import React from "react";
import moment from "moment";
import styles from "./Meta.module.scss";

type Props = {
  date: string;
};

const Meta = ({ date }: Props) => (
  <div className={styles["meta"]}>
    <p className={styles["meta__date"]}>
      {/* @ts-ignore */}
      Published{" "}
      <time
        // @ts-ignore
        itemProp="datePublished"
        dateTime={moment(date).format("YYYY-MM-DD")}
      >
        {moment(date).format("D MMM YYYY")}
      </time>
    </p>
  </div>
);

export default Meta;
