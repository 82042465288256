import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import styles from "./Content.module.scss";

type Props = {
  body: string;
  title: string;
};

const Content = ({ body, title }: Props) => (
  <div className={styles["content"]}
  >
    {/* @ts-ignore */}
    <h1 itemProp="name" className={styles["content__title"]}>
      {title}
    </h1>
    <div 
      // @ts-ignore
      itemProp="articleBody"
      className={styles["content__body"]}
    >
      <MDXRenderer>{body}</MDXRenderer>
    </div>
  </div>
);

export default Content;
